import {
  faFacebook,
  faTelegram,
  faWhatsapp,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const CopyRight = ({ brandName }) => {
  return (
    <div className="dizme_tm_section">
      <div className="dizme_tm_copyright">
        <div className="container">
          <div className="inner">
            <div className="left wow fadeInLeft" data-wow-duration="1s">
              <p>
                Developed with love by{" "}
                <a href="https://natlix.com/" target="_blank" rel="noreferrer">
                  Natlix
                </a>{" "}
                © {new Date().getFullYear()}
              </p>
            </div>
            <div className="right wow fadeInRight" data-wow-duration="1s">
              <ul>
                <li>
                  <a href="https://www.facebook.com/share/A3WwjoAbySvtQxh7/?mibextid=LQQJ4d">
                    <FontAwesomeIcon icon={faFacebook} />
                  </a>
                </li>
                <li>
                  <a href="https://www.youtube.com/@sigmainchemistry4689/featured">
                    <FontAwesomeIcon icon={faYoutube} />
                  </a>{" "}
                </li>
                <li>
                  <a href="https://t.me/01024903937">
                    <FontAwesomeIcon icon={faTelegram} />
                  </a>{" "}
                </li>
                <li>
                  <a href="https://api.whatsapp.com/send?phone=01112204757">
                    <FontAwesomeIcon icon={faWhatsapp} />
                  </a>{" "}
                </li>
                <li>
                  <a href="https://api.whatsapp.com/send?phone=01112204758">
                    <FontAwesomeIcon icon={faWhatsapp} />
                  </a>{" "}
                </li>
                <li>
                  <a href="https://api.whatsapp.com/send?phone=01024903937">
                    <FontAwesomeIcon icon={faWhatsapp} />
                  </a>{" "}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CopyRight;
