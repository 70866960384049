import { Autoplay, Navigation, Pagination } from "swiper/modules";
import Testimonial1 from "../../../assets/testimonials/1.jpeg";
import Testimonial2 from "../../../assets/testimonials/2.jpeg";
import Testimonial3 from "../../../assets/testimonials/3.jpeg";
import Testimonial4 from "../../../assets/testimonials/4.jpeg";
import Testimonial5 from "../../../assets/testimonials/5.jpeg";
import Testimonial6 from "../../../assets/testimonials/6.jpeg";
import Testimonial7 from "../../../assets/testimonials/7.jpeg";
import Testimonial8 from "../../../assets/testimonials/8.jpeg";
import Testimonial9 from "../../../assets/testimonials/9.jpeg";
import Testimonial10 from "../../../assets/testimonials/10.jpeg";
import Testimonial11 from "../../../assets/testimonials/11.jpeg";
import Testimonial12 from "../../../assets/testimonials/12.jpeg";
import Testimonial13 from "../../../assets/testimonials/13.jpeg";
import Testimonial14 from "../../../assets/testimonials/14.jpeg";
import Testimonial15 from "../../../assets/testimonials/15.jpeg";
import Testimonial16 from "../../../assets/testimonials/16.jpeg";
import Testimonial17 from "../../../assets/testimonials/17.jpeg";
import Testimonial18 from "../../../assets/testimonials/18.jpeg";
import Testimonial19 from "../../../assets/testimonials/19.jpeg";
import Testimonial20 from "../../../assets/testimonials/20.jpeg";
import Testimonial21 from "../../../assets/testimonials/21.jpeg";
import Testimonial22 from "../../../assets/testimonials/22.jpeg";
import Testimonial23 from "../../../assets/testimonials/23.jpeg";
import Brush1 from "../../../assets/brushes/testimonials/1.png";
import { Swiper, SwiperSlide } from "swiper/react";

const Testimonial = () => {
  const data = [
    {
      img: Testimonial1,
    },
    {
      img: Testimonial2,
    },
    {
      img: Testimonial3,
    },
    {
      img: Testimonial4,
    },
    {
      img: Testimonial5,
    },
    {
      img: Testimonial6,
    },
    {
      img: Testimonial7,
    },
    {
      img: Testimonial8,
    },
    {
      img: Testimonial9,
    },
    {
      img: Testimonial10,
    },
    {
      img: Testimonial11,
    },
    {
      img: Testimonial12,
    },
    {
      img: Testimonial13,
    },
    {
      img: Testimonial14,
    },
    {
      img: Testimonial15,
    },
    {
      img: Testimonial16,
    },
    {
      img: Testimonial17,
    },
    {
      img: Testimonial18,
    },
    {
      img: Testimonial19,
    },
    {
      img: Testimonial20,
    },
    {
      img: Testimonial21,
    },
    {
      img: Testimonial22,
    },
    {
      img: Testimonial23,
    },
  ];

  const props = {
    slidesPerView: 2,
    loop: true,
    autoplay: {
      delay: 2500,
      disableOnInteraction: false,
    },
    spaceBetween: 50,
    pagination: {
      el: ".owl-dots",
      clickable: true,
    },
  };

  return (
    <div className="dizme_tm_section" id="testimonials">
      <div className="dizme_tm_testimonials">
        <div className="dizme_tm_main_title" data-align="center">
          <span>صورنا</span>
          <h3>مرحبا بك في العائلة الكـيميـائية</h3>
          <p>المتعة في الطريق والتغلب علي عثراته</p>
        </div>
        <div className="list_wrapper">
          <div className="total">
            <div className="in">
              <Swiper
                {...props}
                modules={[Autoplay, Navigation, Pagination]}
                className=""
              >
                {data.map((data, i) => (
                  <SwiperSlide key={i}>
                    <div className="list_inner">
                      <div className="image h-[400px] overflow-hidden">
                        <img src={data.img} alt="" className="object-cover" />
                        <div className="main" data-img-url={data.img} />
                      </div>
                      {/* <div className="details">
                        <span className="category">Youtube</span>
                        <h3 className="title">
                          <span>Car Export</span>
                        </h3>
                      </div>
                      <a
                        className="orido_tm_full_link popup-youtube"
                        href="https://www.youtube.com/watch?v=7e90gBu4pas"
                      /> */}
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
              <div className="owl-dots"></div>
            </div>
            <div className="left_details">
              <div
                className="det_image one wow fadeIn"
                data-wow-duration="1s"
                data-img-url="img/testimonials/2.jpg"
              />
              <div
                className="det_image two wow fadeIn"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
                data-img-url="img/testimonials/1.jpg"
              />
              <div
                className="det_image three wow fadeIn"
                data-wow-duration="1s"
                data-wow-delay="0.4s"
                data-img-url="img/testimonials/3.jpg"
              />
              <div
                className="det_image four wow fadeIn"
                data-wow-duration="1s"
                data-wow-delay="0.6s"
                data-img-url="img/testimonials/4.jpg"
              />
              <span className="circle green animPulse" />
              <span className="circle yellow animPulse" />
              <span className="circle border animPulse" />
            </div>
            <div className="right_details">
              <div
                className="det_image one wow fadeIn"
                data-wow-duration="1s"
                data-img-url="img/testimonials/5.jpg"
              />
              <div
                className="det_image two wow fadeIn"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
                data-img-url="img/testimonials/6.jpg"
              />
              <div
                className="det_image three wow fadeIn"
                data-wow-duration="1s"
                data-wow-delay="0.4s"
                data-img-url="img/testimonials/7.jpg"
              />
              <span className="circle yellow animPulse" />
              <span className="circle purple animPulse" />
              <span className="circle border animPulse" />
            </div>
          </div>
        </div>
        <div className="brush_1 wow fadeInRight" data-wow-duration="1s">
          <img src={Brush1} alt="image" />
        </div>
      </div>
    </div>
  );
};
export default Testimonial;
