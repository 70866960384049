import parse from "html-react-parser";
import { useState } from "react";
import HomeBanner from "../../../assets/slider/1.jpg";
import AvatarBanner from "../../../assets/slider/avatar.png";
import { Link } from "react-router-dom";
const Home = ({ dark }) => {
  const [data, setData] = useState({});

  return (
    <div className="dizme_tm_section" id="home">
      <div className="dizme_tm_hero">
        <div
          className="background"
          data-img-url={HomeBanner}
          style={{ backgroundImage: `url(${HomeBanner})` }}
        />
        <div className="container">
          <div className="content">
            <div className="details">
              <div className="hello ">
                <h3 className="orangeText">Sigma In Chemistry</h3>
              </div>
              <div className="name">
                <h3>
                  <span className="text-accent">الكـيميـائي </span> <br /> محمد
                  يوسف
                </h3>
              </div>
              {/* <div className="job">
                <p>
                  A <span className="greenText">{data && data.mainSkill}</span>{" "}
                  From <span className="purpleText">{data.address}</span>
                </p>
              </div> */}
              {/* <div className="text">
                <p>{data.bio}</p>
              </div> */}
              <div className="button">
                <div className="dizme_tm_button">
                  <Link className="anchor" to="/register">
                    <span>إنضم الينا</span>
                  </Link>
                </div>
                <div className="social">
                  <ul>
                    {data &&
                      data.social &&
                      data.social.map((social, i) => (
                        <li key={i}>
                          <a href="#">
                            <i className={social.icon} />
                          </a>
                        </li>
                      ))}
                  </ul>
                </div>
              </div>
            </div>
            <div className="avatar">
              <div className="image">
                <img src={AvatarBanner} alt="" />
                {data &&
                  data.skills &&
                  data.skills.map(
                    (skill, i) =>
                      skill.icon && (
                        <span
                          key={i}
                          className={`skills ${skill.name} anim_moveBottom`}
                        >
                          {parse(skill.icon)}
                        </span>
                      )
                  )}
              </div>
            </div>
          </div>
        </div>
        <div className="dizme_tm_down">
          <a className="anchor" href="#process">
            <svg
              width="26px"
              height="100%"
              viewBox="0 0 247 390"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              style={{
                fillRule: "evenodd",
                clipRule: "evenodd",
                strokeLinecap: "round",
                strokeLinejoin: "round",
                strokeMiterlimit: "1.5",
              }}
            >
              <path
                id="wheel"
                d="M123.359,79.775l0,72.843"
                style={{
                  fill: "none",
                  stroke: dark ? "#fff" : "#000",
                  strokeWidth: 20,
                }}
              />
              <path
                id="mouse"
                d="M236.717,123.359c0,-62.565 -50.794,-113.359 -113.358,-113.359c-62.565,0 -113.359,50.794 -113.359,113.359l0,143.237c0,62.565 50.794,113.359 113.359,113.359c62.564,0 113.358,-50.794 113.358,-113.359l0,-143.237Z"
                style={{
                  fill: "none",
                  stroke: dark ? "#fff" : "#000",
                  strokeWidth: 20,
                }}
              />
            </svg>
          </a>
        </div>
      </div>
    </div>
  );
};
export default Home;
