import useTable from "../../../../MainComponents/Hooks/useTable.jsx";
import { useAuthState } from "../../../../MainComponents/GlobalContext.jsx";
import HelmetTags from "../../../../MainComponents/HelmetTags.jsx";
import { useState } from "react";

function Students() {
  const [academiclevel, setAcademiclevel] = useState("1");

  const table = useTable("students", "", "", academiclevel, "", academiclevel);

  return (
    <section className="h-full w-full overflow-y-auto px-20 py-20 dark:bg-dark dark:text-light sm:px-10">
      <HelmetTags title="الطلاب | Mr Mohamed Yousef"></HelmetTags>
      <div className="mb-20 flex flex-col items-end gap-20">
        <h2 className="w-full text-end text-4xl font-bold md:text-center">
          الطلاب
        </h2>
        <div className="flex w-full justify-end gap-10 md:justify-center sm:flex-col-reverse sm:items-center">
          <button
            onClick={() => {
              setAcademiclevel("3");
            }}
            className={` rounded-xl border-2 border-secondary  px-2 py-1 font-semibold  transition-all duration-300 hover:bg-light hover:text-secondary   active:scale-90 ${
              academiclevel === "3" && "bg-secondary text-light"
            }`}
          >
            الصف الثالث
          </button>

          <button
            onClick={() => {
              setAcademiclevel("2");
            }}
            className={` rounded-xl border-2 border-secondary  px-2 py-1 font-semibold  transition-all duration-300 hover:bg-light hover:text-secondary   active:scale-90 ${
              academiclevel === "2" && "bg-secondary text-light"
            }`}
          >
            الصف الثاني
          </button>
          <button
            onClick={() => {
              setAcademiclevel("1");
            }}
            className={` rounded-xl border-2 border-secondary  px-2 py-1 font-semibold  transition-all duration-300 hover:bg-light hover:text-secondary   active:scale-90 ${
              academiclevel === "1" && "bg-secondary text-light"
            }`}
          >
            الصف الأول
          </button>
        </div>
      </div>
      {/**
       * //!---------Table-----
       */}
      <div className="h-fit  w-full">{table}</div>
    </section>
  );
}

export default Students;
