import { Link } from "react-router-dom";

const Header = ({ logo }) => {
  return (
    <div className="dizme_tm_header">
      <div className="container">
        <div className="inner">
          <div className="logo">
            <Link to="/">{logo && <img src={logo} alt="" />}</Link>
          </div>
          <div className="menu">
            <ul className="anchor_nav">
              <li className="current">
                <a href="#home">الرئيسية</a>
              </li>
              <li>
                <a href="#about">من نحن</a>
              </li>
              <li>
                <a href="#portfolio">أعمالنا</a>
              </li>
              {/* <li>
                <a href="#service">الخدمات</a>
              </li> */}
              {/*              
              <li>
                <a href="#contact">Contact</a>
              </li> */}
              <li className="download_cv">
                <Link to="/signin">
                  <span>تسجيل الدخول</span>
                </Link>
              </li>
              <li className="download_cv">
                <Link to="/register">
                  <span>تسجيل حساب</span>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Header;
