import { useState } from "react";
import { Link } from "react-router-dom";
const MobileMenu = ({ logo }) => {
  const [toggle, setToggle] = useState(false);
  return (
    <div className="dizme_tm_mobile_menu">
      <div className="mobile_menu_inner">
        <div className="mobile_in">
          <div className="logo">
            <a href="#">
              <img src={logo ? logo : "img/logo/logo.png"} alt="image" />
            </a>
          </div>
          <div className="trigger">
            <div
              className={`hamburger hamburger--slider ${
                toggle ? "is-active" : ""
              }`}
              onClick={() => setToggle(!toggle)}
            >
              <div className="hamburger-box">
                <div className="hamburger-inner" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="dropdown" style={{ display: toggle ? "block" : "none" }}>
        <div className="dropdown_inner">
          <ul className="anchor_nav">
            <li className="current">
              <a href="#home" onClick={() => setToggle(false)}>
                الرئيسية
              </a>
            </li>
            <li>
              <a onClick={() => setToggle(false)} href="#about">
                من نحن
              </a>
            </li>
            <li>
              <a onClick={() => setToggle(false)} href="#testimonials">
                أعمالنا
              </a>
            </li>

            <li className="download_cv">
              <Link to="/signin">
                <span>تسجيل الدخول</span>
              </Link>
            </li>
            <li className="download_cv">
              <Link to="/register">
                <span>تسجيل حساب</span>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
export default MobileMenu;
