import Counter from "./Counter";
import About1 from "../../../assets/about/1.png";
import AboutBrushOne from "../../../assets/brushes/about/1.png";
import AboutBrushTwo from "../../../assets/brushes/about/2.png";
import { Link } from "react-router-dom";

const About = ({ dark }) => {
  return (
    <div className="dizme_tm_section" id="about">
      <div className="dizme_tm_about">
        <div className="container">
          <div className="wrapper">
            <div className="left">
              <div className="image">
                <img src={About1} alt="" />
                {/* <div className="numbers year">
                  <div className="wrapper">
                    <h3>
                      <Counter end={18} />
                    </h3>
                    <span className="name">
                      Years of
                      <br />
                      Success
                    </span>
                  </div>
                </div> */}
                {/* <div className="numbers project">
                  <div className="wrapper">
                    <h3>
                      <Counter end={9} />K
                    </h3>
                    <span className="name">
                      Total
                      <br />
                      Projects
                    </span>
                  </div>
                </div> */}
              </div>
            </div>
            <div className="right">
              <div className="title wow fadeInUp" data-wow-duration="1s">
                <span>ليه الكيميائي ؟</span>
                <h3>الكـيميـائي محمد يوسف</h3>
              </div>
              <div className="text wow fadeInUp" data-wow-duration="1s">
                <p>
                  لأن طلبة كتير بتواجه صعوبة في فهم الكيميا وحل المسائل وفرتلك
                  في منصة سيجما كل الي نفسك فيه عشان اخليلك المادة بسيطة ومُمتعة
                </p>
              </div>
              <div
                className="dizme_tm_button wow fadeInUp"
                data-wow-duration="1s"
              >
                <Link className="anchor" to="/register">
                  <span>إنضم إلينا</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="brush_1 wow fadeInLeft" data-wow-duration="1s">
          <img src={AboutBrushOne} alt="" />
        </div>
        <div className="brush_2 wow fadeInRight" data-wow-duration="1s">
          <img src={AboutBrushTwo} alt="" />
        </div>
      </div>
    </div>
  );
};
export default About;
