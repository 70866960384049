import { Fragment, useEffect, useState } from "react";
import CopyRight from "../components/CopyRight";
import ImageView from "../components/popup/ImageView";
import VideoPopup from "../components/popup/VideoPopup";
import useWowJs, {
  dataImage,
  scrollTop,
  // scroll_,
  stickyNav,
  useScrollSpy,
} from "../utilits";
import Cursor from "./Cursor";
import Header from "./Header";
import MobileMenu from "./MobileMenu";
import PreLoader from "./PreLoader";
import Progressbar from "./Progressbar";
import Logo from "../../../assets/logo-light.png";

const Layout = ({ children, dark }) => {
  const [siteInfo, setSiteInfo] = useState({});
  // useEffect(async () => {
  //   setSiteInfo(await fatchData("/static/siteSetting.json"));
  //   dataImage();
  // }, []);
  useEffect(() => {
    window.addEventListener("scroll", stickyNav);
    window.addEventListener("scroll", scrollTop);
  }, []);
  useScrollSpy();
  useWowJs();

  return (
    <Fragment>
      <PreLoader />
      <ImageView />
      <VideoPopup />
      <div className="dizme_tm_all_wrap" data-magic-cursor="show">
        <MobileMenu logo={Logo} />
        <Header logo={Logo} />
        {children}
        <CopyRight brandName={siteInfo && siteInfo.brandName} />
        <Cursor />
        <Progressbar />
      </div>
    </Fragment>
  );
};
export default Layout;
