import { useRef } from "react";
import HelmetTags from "../../../MainComponents/HelmetTags";
import useFade from "../../../MainComponents/Hooks/useFade";
import { useState } from "react";
import { Loader } from "../../../MainComponents";
import { useEffect } from "react";
import useAxios from "../../../MainComponents/Hooks/useAxios";
import { useParams } from "react-router-dom";
import { Col, Row } from "antd";
import StudentQuestion from "./StudentQuestion";
// import html2pdf from "html2pdf.js";

const StudentShowQuestionBank = () => {
  const [questions, setQuestions] = useState([]);
  const { ID } = useParams();

  const TitleWrapper = useRef();
  const [fade] = useFade(TitleWrapper, 100);

  const [questionsBankData, questionsBankErrors, questionsBankLoading] =
    useAxios(
      `${process.env.REACT_APP_GET_QUESTION_BANCK_STUDNET_VIEW_QUESTION_API}/${ID}`,
      "GET",
      "GET"
    );

  useEffect(() => {
    if (questionsBankData?.data) setQuestions(questionsBankData?.data);
  }, [questionsBankData]);

  // const handleExportPDF = () => {
  //   const element = document.getElementById("export-content");

  //   html2pdf()
  //     .set({
  //       margin: 1,
  //       filename: "exported.pdf",
  //       image: { type: "jpeg", quality: 0.98 },
  //       html2canvas: { dpi: 192, letterRendering: true },
  //       jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
  //     })
  //     .from(element)
  //     .save();
  // };

  if (questionsBankLoading) {
    return <Loader />;
  }
  if (questionsBankErrors) {
    return (
      <div className="flex h-screen w-full items-center justify-center">
        <p> لا يوجد اي اسئلة حاليا </p>
      </div>
    );
  }
  return (
    <section className=" width mb-32 h-full dark:bg-dark dark:text-light ">
      <HelmetTags title="بنك الأسئلة | Mr Mohamed Yousef"></HelmetTags>{" "}
      <div
        ref={TitleWrapper}
        className="title-wrapper  mt-36 flex flex-col items-center mb-32"
      >
        <h2
          className={`mb-10 text-center text-5xl font-bold transition-all duration-300 ease-linear ${
            fade ? "translate-y-14 opacity-0" : "translate-y-0 opacity-100"
          } `}
        >
          اختر السؤال الصحيح
        </h2>
        {/* <button
          className="bg-secondary text-white p-2 px-7 rounded-xl border-2 border-secondary hover:bg-transparent duration-300 hover:scale-110 hover:text-secondary "
          onClick={handleExportPDF}
        >
          تحميل الاسئلة
        </button> */}
      </div>
      <div id="export-content">
        {questions.map((question, i) => (
          <StudentQuestion key={i} question={question} index={i} />
        ))}
      </div>
    </section>
  );
};

export default StudentShowQuestionBank;
