import Home from "./components/Home";
import Process from "./components/Process";
import About from "./components/About";
import Portfolio from "./components/Portfolio";
import Skills from "./components/Skills";
import Service from "./components/Service";
import Testimonial from "./components/Testimonial";
import Partners from "./components/Partners";
import Layout from "./layout/Layout";
import HelmetTags from "../../MainComponents/HelmetTags";

import "../../SASS/landingPage.scss";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

function LandingPage() {
  return (
    <div className="landingPage" dir="rtl">
      <HelmetTags title="Mr Mohamed Yousef" index>
        <link rel="canonical" href="https://sigmainchem.com" />
      </HelmetTags>
      <Layout>
        <Home />
        <Process />
        <About />
        {/* <Portfolio /> */}
        {/* <Skills /> */}
        {/* <Service /> */}
        <Testimonial />
        {/* <Partners /> */}
        {/* <News />
        <Newsletter />
        <Contact /> */}
      </Layout>
    </div>
  );
}

export default LandingPage;
